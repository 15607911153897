<template class="orderdetail">
  <v-card>
    <v-card-title>注文履歴</v-card-title>
    <v-card-subtitle class="px-6" style="font-size:1rem;">
      <v-row>
        <v-col cols="4">
          <div>
            <span style="font-weight:bold;">注文ID: </span>
            <span>{{ orderProgressList.order_id }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">注文番号: </span>
            <span>{{ orderProgressList.order_no }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">注文日時: </span>
            <span>{{ orderProgressList.order_dt }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">決済金額: </span>
            <span :style="isEqual ? '' : 'color:red;'">{{ formatData(orderProgressList.order_total) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <div>
            <span style="font-weight:bold;">注文者: </span>
            <span>{{ orderProgressList.mbr_name }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">お届け先: </span>
            <span>{{ orderProgressList.addr_name }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">郵便番号: </span>
            <span>{{ orderProgressList.addr_zip }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">お届け先住所: </span>
            <span>{{ orderProgressList.addr_addr1 }} {{ orderProgressList.addr_addr2 }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card-title>注文詳細</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="detailHeaders"
        :items="orderProgressList.details"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.order_qty`]="{ item }">
          {{ formatData(item.order_qty) }}
        </template>
        <template v-slot:[`item.order_tip`]="{ item }">
          {{ formatData(item.order_tip) }}
        </template>
        <template v-slot:[`item.itemTotal`]="{ item }">
          {{ formatData(item.order_tip * item.order_qty) }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>小計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ totalPrice }}</label>
            </td>
          </tr>
          <tr>
            <td
              :colspan="5"
            >
              <label>送料</label>
            </td>
            <td
              style="text-align: right;"
            >
              <label>{{ formatData(orderProgressList.shipping_fee) }}</label>
            </td>
          </tr>
          <tr v-if="couponShow">
            <td
              :colspan="5"
            >
              <label>クーポン</label>
            </td>
            <td
              style="text-align: right;"
            >
              <label style="color:red;">-{{ formatData(orderProgressList.coupon_discount) }}</label>
            </td>
          </tr>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumPrice }}</label>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-title>操作履歴</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="progressHeaders"
        :items="orderProgressList.progresses"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.id`]="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-slot:[`item.order_proc_memo`]="{ item }">
          <div class="order_proc_memo" :title="`${item.order_proc_memo}`">
            {{ item.order_proc_memo }}
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div class="os_desc" :title="`${item.os_desc}`">
            {{ item.os_code }} : {{ item.os_desc }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        outlined
        elevation="0"
        @click="goBack"
      >
        <span>戻る</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  components: {
  },
  data: () => ({
    detailHeaders: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'detail_id',
      },
      {
        text: '商品コード',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'product_code',
      },
      {
        text: '商品名',
        align: 'left',
        sortable: false,
        width: '28%',
        value: 'product_name',
      },
      {
        text: '注文数量',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'order_qty',
      },
      {
        text: '単価',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'order_tip',
      },
      {
        text: '金額',
        align: 'right',
        sortable: false,
        width: '15%',
        value: 'itemTotal',
      },
    ],
    progressHeaders: [
      {
        text: 'No',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'id',
      },
      {
        text: '処理日時',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'order_proc_dt',
      },
      {
        text: '処理メモ',
        align: 'left',
        sortable: false,
        width: '52%',
        value: 'order_proc_memo',
      },
      {
        text: '注文状態',
        align: 'left',
        sortable: false,
        width: '21%',
        value: 'os_desc',
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['orderProgressList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    isEqual() {
      if (this.orderProgressList.order_total && this.sumPrice === this.orderProgressList.order_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) {
        console.log(this.sumPrice, this.orderProgressList.order_total)

        return true
      }
      console.log('false', this.sumPrice, this.orderProgressList.order_total)

      return false
    },

    couponShow() {
      if (this.orderProgressList.coupon_discount && this.orderProgressList.coupon_discount !== 0) {
        return true
      }

      return false
    },

    sumPrice() {
      let sum = 0
      if (this.orderProgressList.details) {
        this.orderProgressList.details.forEach(a => {
          sum += a.order_tip * a.order_qty
        })
        sum = sum - this.orderProgressList.coupon_discount + this.orderProgressList.shipping_fee
      }

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    totalPrice() {
      let sum = 0
      if (this.orderProgressList.details) {
        this.orderProgressList.details.forEach(a => {
          sum += a.order_tip * a.order_qty
        })
      }

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
  watch: {
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('orderStore', ['loadOrderProgress']),

    // fromにより戻る場所を決める
    goBack() {
      console.log('goBack:', this.$route.query)
      if (this.$route.query.from === 'cancel') {
        this.$router.push({
          name: 'ordercancel-list',
        })
      } else {
        this.$router.push({
          name: 'order-by-member-list',
        })
      }
    },

    loadData() {
      this.setOverlayStatus(true)
      this.loadOrderProgress(this.$route.params.id).finally(() => {
        this.setOverlayStatus(false)
      })
    },

  },
}
</script>

<style scoped>
.theme--light.v-data-table >>> th {
  border-top: 0;
}
.order_proc_memo{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 20rem;
}
.os_desc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 10rem;
}
</style>
