var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("注文履歴")]),_c('v-card-subtitle',{staticClass:"px-6",staticStyle:{"font-size":"1rem"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("注文ID: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.order_id))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("注文番号: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.order_no))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("注文日時: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.order_dt))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("決済金額: ")]),_c('span',{style:(_vm.isEqual ? '' : 'color:red;')},[_vm._v(_vm._s(_vm.formatData(_vm.orderProgressList.order_total)))])])]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("注文者: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.mbr_name))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("お届け先: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.addr_name))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("郵便番号: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.addr_zip))])]),_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("お届け先住所: ")]),_c('span',[_vm._v(_vm._s(_vm.orderProgressList.addr_addr1)+" "+_vm._s(_vm.orderProgressList.addr_addr2))])])])],1)],1),_c('v-divider'),_c('v-card-title',[_vm._v("注文詳細")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border":"thin solid rgba(0, 0, 0, 0.14)"},attrs:{"headers":_vm.detailHeaders,"items":_vm.orderProgressList.details,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.order_qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.order_qty))+" ")]}},{key:"item.order_tip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.order_tip))+" ")]}},{key:"item.itemTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.order_tip * item.order_qty))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"font-weight-bold",staticStyle:{"background":"#fafafa"},attrs:{"colspan":5}},[_c('label',[_vm._v("小計")])]),_c('td',{staticClass:"font-weight-bold",staticStyle:{"text-align":"right","background":"#fafafa"}},[_c('label',[_vm._v(_vm._s(_vm.totalPrice))])])]),_c('tr',[_c('td',{attrs:{"colspan":5}},[_c('label',[_vm._v("送料")])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('label',[_vm._v(_vm._s(_vm.formatData(_vm.orderProgressList.shipping_fee)))])])]),(_vm.couponShow)?_c('tr',[_c('td',{attrs:{"colspan":5}},[_c('label',[_vm._v("クーポン")])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('label',{staticStyle:{"color":"red"}},[_vm._v("-"+_vm._s(_vm.formatData(_vm.orderProgressList.coupon_discount)))])])]):_vm._e(),_c('tr',[_c('td',{staticClass:"font-weight-bold",staticStyle:{"background":"#fafafa"},attrs:{"colspan":5}},[_c('label',[_vm._v("合計")])]),_c('td',{staticClass:"font-weight-bold",staticStyle:{"text-align":"right","background":"#fafafa"}},[_c('label',[_vm._v(_vm._s(_vm.sumPrice))])])])]},proxy:true}],null,true)})],1),_c('v-divider'),_c('v-card-title',[_vm._v("操作履歴")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border":"thin solid rgba(0, 0, 0, 0.14)"},attrs:{"headers":_vm.progressHeaders,"items":_vm.orderProgressList.progresses,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.order_proc_memo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"order_proc_memo",attrs:{"title":("" + (item.order_proc_memo))}},[_vm._v(" "+_vm._s(item.order_proc_memo)+" ")])]}},{key:"item.os_desc",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"os_desc",attrs:{"title":("" + (item.os_desc))}},[_vm._v(" "+_vm._s(item.os_code)+" : "+_vm._s(item.os_desc)+" ")])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":"","elevation":"0"},on:{"click":_vm.goBack}},[_c('span',[_vm._v("戻る")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }